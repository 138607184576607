import bir from "./1.png";
import iki from "./2.png";
import uc from "./3.png";
import dort from "./4.png";
import bes from "./5.png";
import alti from "./6.png";
import yedi from "./7.png";

const calismalar = [bir, iki, uc, dort, bes, alti, yedi];

export default calismalar;
