import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import Logo from "./assets/yamanlogo.svg";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Carousel from "react-bootstrap/Carousel";
import calismalar from "./assets/calisma";
import "./App.css";

function App() {
  return (
    <div>
      <Navbar
        collapseOnSelect
        expand="lg"
        variant="dark"
        className="navbar p-0"
      >
        <Container>
          <Navbar.Brand href="#home" className="me-auto p-0">
            <div style={{ padding: "22px 0px" }}>
              <img alt="" src={Logo} width="30" className="d-inline-block" />
            </div>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link eventKey={2} href="#home" className="text-light p-0">
                <div style={{ padding: "25px 10px", fontWeight: "700" }}>
                  Yaman Elektrik
                </div>
              </Nav.Link>
            </Nav>
            <Nav>
              <Nav.Link
                eventKey={2}
                href="#hizmetlerimiz"
                className="text-light p-0"
              >
                <div className="phone-item" style={{ fontWeight: "700" }}>
                  Hizmetlerimiz
                </div>
              </Nav.Link>
              <Nav.Link
                eventKey={2}
                href="#calismalarimiz"
                className="text-light p-0"
              >
                <div className="phone-item" style={{ fontWeight: "700" }}>
                  Çalışmalarımız
                </div>
              </Nav.Link>

              <Nav.Link href="tel:+90 535 345 14 84" className="text-light p-0">
                <div className="d-flex phone-item">
                  <span className="material-symbols-outlined">call</span>
                  <div className="ms-1" style={{ fontWeight: "500" }}>
                    0535 345 14 84
                  </div>
                </div>
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <Container>
        <section id="home">
          <div className="text-center" style={{ marginTop: "150px" }}>
            <img src={Logo} alt="logo" style={{ width: "15%" }} />
            <div id="head-text" className="text-white mt-4">
              <h1 style={{ fontWeight: "700" }}>
                Her İşin Bir Ustası,
                <br /> Her Ustanın Bir Adresi Vardır.
              </h1>
              <p style={{ fontWeight: "500" }}>İstanbul / Beylikdüzü</p>
            </div>
          </div>
        </section>
        <section id="hizmetlerimiz">
          <div className="text-center">
            <div id="head-text" className="text-white mt-4 mb-5">
              <h2 style={{ fontWeight: "700" }}>Hizmetlerimiz</h2>
            </div>
            <Row>
              <Col>
                <div className="card-hizmet text-white">
                  <h4>Elektrik Arıza</h4>
                </div>
              </Col>
              <Col>
                <div className="card-hizmet text-white">
                  <h4>Görüntülü Diafon</h4>
                </div>
              </Col>
              <Col>
                <div className="card-hizmet text-white">
                  <h4>Montaj</h4>
                </div>
              </Col>
            </Row>
          </div>
        </section>
        <section id="calismalarimiz">
          <div className="text-center">
            <div id="head-text" className="text-white mt-4 mb-5">
              <h2 style={{ fontWeight: "700" }}>Çalışmalarımız</h2>
            </div>
            <Carousel>
              <Carousel.Item interval={1000}>
                <img
                  className="d-block w-100"
                  src={calismalar[0]}
                  alt="First slide"
                />
              </Carousel.Item>
              <Carousel.Item interval={1000}>
                <img
                  className="d-block w-100"
                  src={calismalar[1]}
                  alt="First slide"
                />
              </Carousel.Item>
              <Carousel.Item interval={1000}>
                <img
                  className="d-block w-100"
                  src={calismalar[2]}
                  alt="First slide"
                />
              </Carousel.Item>
              <Carousel.Item interval={1000}>
                <img
                  className="d-block w-100"
                  src={calismalar[3]}
                  alt="First slide"
                />
              </Carousel.Item>
              <Carousel.Item interval={1000}>
                <img
                  className="d-block w-100"
                  src={calismalar[4]}
                  alt="First slide"
                />
              </Carousel.Item>
              <Carousel.Item interval={1000}>
                <img
                  className="d-block w-100"
                  src={calismalar[5]}
                  alt="First slide"
                />
              </Carousel.Item>
              <Carousel.Item interval={1000}>
                <img
                  className="d-block w-100"
                  src={calismalar[6]}
                  alt="First slide"
                />
              </Carousel.Item>
            </Carousel>
          </div>
        </section>
      </Container>
      <footer>
        <Container className="text-white">
          <Row>
            <Col>
              <div>
                <div className="d-flex">
                  <img
                    alt=""
                    src={Logo}
                    width="30"
                    className="d-inline-block"
                  />
                  <div style={{ padding: "25px 10px", fontWeight: "700" }}>
                    Yaman Elektrik
                  </div>
                </div>
              </div>
            </Col>
            <Col>
              <div className="text-center" style={{ padding: "12.5px 0px" }}>
                ©Copyright <strong>Yaman Elektrik</strong> All Rights Reserved{" "}
                <br />
                Created by{" "}
                <a
                  href="http://github.com/berkaygulerr"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{
                    textDecoration: "none",
                  }}
                >
                  <strong>Berkay Güler</strong>
                </a>
              </div>
            </Col>
            <Col>
              <Nav.Link href="tel:+90 535 345 14 84" className="text-light p-0">
                <div
                  className="d-flex"
                  style={{
                    padding: "25px 0px",
                    float: "right",
                  }}
                >
                  <span className="material-symbols-outlined">call</span>
                  <div className="ms-1" style={{ fontWeight: "500" }}>
                    0535 345 14 84
                  </div>
                </div>
              </Nav.Link>
            </Col>
          </Row>
        </Container>
      </footer>
    </div>
  );
}

export default App;
